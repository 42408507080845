import { Typography } from "@mui/material";
import React from "react";

export default function About() {
  return (
    <div>
      {/* <Typography variant="h2" color="white">Metrics</Typography> */}
      <Typography variant="h2" color="white" sx={{mb: 5}}>About</Typography>
      <p>
        <Typography variant="body" color="white">
          Alex Fiebig specializes in capturing the thrill and excitement of adventure, tourism, and extreme sports through stunning videography. From showcasing the beauty of nature to highlighting the innovative features of exciting products, Alex knows how to bring your vision to life. Whether you're looking for promotional content for your business or a unique way to document your own adventures, Alex the expertise and equipment to deliver high-quality results that truly capture the essence of your story.
        </Typography>
      </p>
      <p>
        <Typography variant="body" color="white">
          Trusted by industry leading brands such as Puma and Sennheiser Alex has the experience and expertise to deliver professional videos at the highest level. From tourism videos to luxury cars his highly emotive videos leave people needing to experience it for themselves.
        </Typography>
      </p>
      <Typography variant="body" color="white">
        Let Alex help you share your passion and excitement with the world!
      </Typography>
    </div>
  );
}