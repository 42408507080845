import React, {useContext, useEffect} from 'react';
import ScrollContext from '../context/ScrollContext';
import ValueProp from '../components/ValueProp';
import Work from '../components/Work';
import About from '../components/About';
import Contact from '../components/Contact';
import Page from './Page';
import { useLocation } from 'react-router-dom';


export default function MainPage({containerSize}) {
  const {scrollToRef, value} = useContext(ScrollContext);
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      scrollToRef(location.hash.slice(1));
    }
  })
  return (
    <Page containerSize={containerSize} image={true}>
      <ValueProp/>
      <Work phoRef={value.photography}/>
      <div style={{height: '50px'}}/>
      <About/>
      <div style={{height: '50px'}}/>
      <div ref={value.contact}><Contact/></div>
    </Page>
  )
}