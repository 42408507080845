import { Instagram, YouTube } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";

function SocialButton({children, link}) {
  return (
    <IconButton size="small" sx={{color: "white"}} onClick={()=>window.open(link)}>{children}</IconButton>
  )
}

export default function Footer() {
  const padding = 4;
  return (
    <Box sx={{display: "flex", justifyContent: "space-between", paddingTop: padding, paddingBottom: padding}}>
      <Typography variant="body" color="white" fontSize="14pt">© 2023 Alex Fiebig</Typography>
      <Box>
        <SocialButton link="https://www.instagram.com/alex_fiebig/"><Instagram/></SocialButton>
        <SocialButton link="https://www.youtube.com/@alexfiebig4074"><YouTube/></SocialButton>
      </Box>
    </Box>
  );
}