import React from "react"

function centredImageStyle(height, minHeight, maxHeight) {
  return {
    height: height,
    minHeight: minHeight,
    // objectFit: "cover",
    objectFit: "cover",
    objectPosition: "center",
    // width: "100%",
    maxHeight: maxHeight,
  }
}
export default function BackgroundImage({src, alt, height, minHeight, maxHeight}) {
  return (
    <div style={{position: "absolute", top: 0, left: 0, right: 0, zIndex: -1, overflow: "hidden", width: "100%", display: "flex", justifyContent: "center"}}>
      <img src={src} alt={alt} style={centredImageStyle(height, minHeight, maxHeight)}/>
    </div>
  )
}