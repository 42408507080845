
import { createTheme } from '@mui/material';

const myTheme = createTheme({
  palette: {
    mode: 'dark',
    secondary: {
      main: '#ea6e43'
    }
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    h1: {
      fontFamily: "LogoFont"
    },
    h2: {
      fontSize: '1.75rem',
    },
    h3: {
      fontSize: '1.7rem',
    },
    body: {
      fontSize: '16pt',
      fontWeight: 100,
    },
    fontFamily: 'Manrope,sans-serif'
  },
  components: {
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '1rem',
        },
      },
    },
  }
});

export default myTheme;