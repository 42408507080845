import React, { useContext } from "react";
import { Button, Typography } from "@mui/material";
import logo from "../resources/logo";
// import "../index.css"
import "../fonts/trangher/Trangher.ttf";
import ScreenSizeContext from "../context/ScreenContext";
import ScrollContext from "../context/ScrollContext";

function Partners() {
  const partners = ["puma.png", "sennheiser.png", "solotel.png", "brooks.png", "running_heroes.png"]
  // const partners = ["Puma", "Sennheiser", "Solotel", "Brooks", "Running Heroes", "Legends Gym"]
  return (
    <div style={{paddingBottom: 40, width: "100%", display: "flex", justifyContent: "space-evenly", flexWrap: "wrap", gap: "30px"}}>
      {partners.map((partner, i) => (
        <div key={i}>
          <img src={`/images/partners/${partner}`} alt={partner} style={{height: 20}}/>
          {/* <Typography variant="body" style={{textAlign: "center", maxWidth:"800px", fontSize: 20}}>
            {partner}
          </Typography> */}
        </div>))}
    </div>
  )
}

function Overlay() {
  const {scrollToRef} = useContext(ScrollContext);
  const {mobile} = useContext(ScreenSizeContext);
  // const backgroundColor = "rgba(0,0,0,0.2)"
  // const backgroundColor = "rgba(0,0,0,0.4)"
  const backgroundColor = "rgba(0,0,0,0)"
  const msg = "Inspiring and impactful cinematography"
  // const msg = "Stunning and impactful cinematography"
  return (
    <div style={{paddingTop: 125, paddingBottom: 20, width: "100%", minHeight: "100vh", backgroundColor: backgroundColor, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between"}}>
      <div>
        <div style={{padding: 0, paddingTop: 10}}>
          <Typography variant={mobile?"h3":"h1"} style={{fontWeight: "100", textAlign: "center", maxWidth:"800px", color: "white"}}>
            {logo()}
          </Typography>
        </div>
        <Button sx={{marginTop: 2, fontSize: "1.25rem"}} variant="contained" color="secondary" onClick={()=>scrollToRef("contact")}>
          <Typography variant="body" fontSize="16pt" color="white">Get in touch</Typography>
        </Button>
        
      </div>
      <div style={{width: "100%", padding: 0, margin: 0, display: "flex", flexDirection:"column", alignItems:"center"}}>
        {mobile ?
          <Typography variant="h6" style={{textAlign: "center", maxWidth:"800px", fontWeight:"bold"}}>{msg}</Typography>
          :<Typography variant="body" style={{textAlign: "center", maxWidth:"800px", fontSize: 20}}>
            {msg}
          </Typography>
        }
        {/* <div style={{width: "100%", position: "absolute", zIndex:2, bottom: 0, left: 0, right: 0, backgroundColor: backgroundColor, display: "flex", flexDirection: "column", alignItems: "center", }}> */}
        <div style={{height: "10vh"}}/>
        {/* <Typography variant="body" color="white" fontSize={24}>Trusted By</Typography>
        <div style={{height: "4vh"}}/> */}
        <Partners />
        {/* </div> */}
      </div>
    </div>
  )
}

export default function ValueProp () {
  return (
    <div style={{position: "relative"}}>
      <Overlay/>
    </div>
  );
}
