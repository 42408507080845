import { CssBaseline, ThemeProvider } from '@mui/material';
import './App.css';
import myTheme from './themes/Theme';
import { useRef } from 'react';

import "./fonts/trangher/Trangher.ttf";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Cinematography from './screens/Cinematography';
import MainPage from './screens/MainScreen';
import { ScrollProvider } from './context/ScrollContext';

function App() {
  const containerSize = 'xl';
  const ref2 = useRef();
  const ref3 = useRef();
  return (
    <ThemeProvider theme={myTheme}>
      <BrowserRouter>
        <ScrollProvider value={{"contact": ref3, "cinematography": "/cinematography", "photography": ref2}}>
          <CssBaseline/>
            <Routes>
              <Route path="/" element={<MainPage containerSize={containerSize}/>}/>
              <Route path="*" element={<MainPage containerSize={containerSize}/>}/>
              <Route path="/cinematography" element={<Cinematography containerSize={containerSize}/>}/>
            </Routes>
        </ScrollProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
