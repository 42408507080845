import React, {createContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

function isMobile() {
  return window.innerWidth < 600;
}

const ScreenSizeContext = createContext();

export function ScreenSizeProvider({children}) {
  const [mobile, setMobile] = useState(isMobile());
  const onResize = () => {setMobile(isMobile)};
  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {window.removeEventListener('resize', onResize)};
  },[]);
  
  return (
    <ScreenSizeContext.Provider value={{mobile}}>
      {children}
    </ScreenSizeContext.Provider>
  )
}

export default ScreenSizeContext;

ScreenSizeProvider.propTypes = {
  children: PropTypes.node.isRequired
}