import React, { createContext } from 'react';
import { PropTypes } from 'prop-types';

const ScrollContext = createContext();

function scrollToRef(refName, navigate) {
  const yOffset = 70;
  const ref = ScrollContext.refs[refName];
  // typeof ref is string
  if (typeof ref === 'string') {
    navigate(ref)
    return;
  } else {
    navigate && navigate(`/#${refName}`);
    const pos = ref.current?.getBoundingClientRect().top+window.pageYOffset-yOffset;
    // ref.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
    window.scrollTo({top: pos, behavior: 'smooth'})
  }
}

export function ScrollProvider({children, value}) {
  ScrollContext.refs = value;
  return (
    <ScrollContext.Provider value={{scrollToRef, value}}>
      {children}
    </ScrollContext.Provider>
  )
}

export default ScrollContext;

ScrollProvider.propTypes = {
  children: PropTypes.node.isRequired
}