import { Box, Button, Container, IconButton, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import logo from '../resources/logo';
import ScreenSizeContext from '../context/ScreenContext';
import { Menu } from '@mui/icons-material';
import ScrollContext from '../context/ScrollContext';
import { useNavigate } from 'react-router-dom';


function HeaderButton({children, closeMenu}) {
  return (
    <Button sx={{color:"white","&:hover":{backgroundColor:"transparent"}}} onClick={()=>closeMenu()}>
      {children}
    </Button>
  )
}
function SectionHeading({heading, closeMenu}) {
  const navigate = useNavigate();
  const {scrollToRef} = useContext(ScrollContext);
  const ref = heading.toLowerCase();
  return (
    <HeaderButton sx={{color:"white"}} closeMenu={()=>{closeMenu(); scrollToRef(ref, navigate)}}>
      <Typography variant="h7">{heading}</Typography>
    </HeaderButton>
  )
}

function Logo({title}) {
  const navigate = useNavigate();
  return (
    <HeaderButton sx={{color:"white"}} closeMenu={()=>{navigate('/'); window.scrollTo({top:0, behavior: "smooth"})}}>
      <Typography variant="h5">{title}</Typography>
    </HeaderButton>
  )
}

export default function Header({size, pad=false}) {
  const {mobile} = useContext(ScreenSizeContext);
  const [openMenu, setOpenMenu] = React.useState(false);
  const horzPadding = 0;
  const ref = React.useRef();
  const [height, setHeight] = React.useState(100);
  const sections = ["Cinematography", "Photography", "Contact"]

  useEffect(() => {
    setHeight(ref.current.clientHeight)
  },[])

  useEffect(() => {
    setOpenMenu(false);
  }, [mobile]);
  return (
    <div>
      <Box ref={ref} sx={{ position: "fixed", zIndex: 3, top: 0, p: 1, width: "100%", paddingLeft: horzPadding, paddingRight: horzPadding, backgroundColor: "rgba(0,0,0,0.8)"}}>
        <Container maxWidth={size} sx={{display: "flex", justifyContent: "space-between"}}>
          <Logo title={logo()}/>
          {mobile?
          <IconButton size="small" onClick={()=>setOpenMenu(!openMenu)}><Menu/></IconButton>
          :<Box sx={{display: "flex"}}>
            {sections.map((section, i) => (<SectionHeading key={i} heading={section.toUpperCase()} closeMenu={()=>setOpenMenu(false)}/>))}
          </Box>}
        </Container>
        {openMenu &&
          <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "rgba(0,0,0,0.8)"}}>
            {sections.map((section, i) => (<SectionHeading key={i} heading={section.toUpperCase()} closeMenu={()=>setOpenMenu(false)}/>))}
          </Box>
        }
      </Box>
      {pad && <Box sx={{ height: height, width:"100%", bg: "red" }}></Box>}
    </div>
  )
}