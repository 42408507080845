import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ScreenSizeContext from '../context/ScreenContext';
import { IconButton, Tooltip } from '@mui/material';
import { ArrowOutward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';


function WorkBox({photo, text, link, portrait}) {
  const navigate = useNavigate();
  const {mobile} = useContext(ScreenSizeContext);
  const ratio = "16/9";
  const rad = 3;
  const [show, setShow] = React.useState(false)
  return (
    <Box sx={{ width: '100%' , display: "flex", flexDirection: "column", alignItems: "center"}}>
      {mobile && <Typography variant='body' sx={{color: "white", pb: 5}}>{text}</Typography>}
      <Box sx={{aspectRatio: ratio, width: "100%", borderRadius: rad, overflow: "hidden", display: "relative"}}>
        <Tooltip title={link}>
          <Button onMouseEnter={()=>setShow(true)} onMouseLeave={()=>setShow(false)} disableRipple={true} onClick={()=>{link.startsWith("/")?navigate(link):window.open(link)}} sx={{m:0, height: "100%", width: "100%", p:0}}>
            <img src={photo} alt="photos" style={{...(portrait?{width: "100%"}: {height: "100%"})}}/>
            {mobile && <IconButton sx={{position: "absolute", backgroundColor: "rgba(0,0,0,0.3)", zIndex: "3", top: 0, right: 0}}><ArrowOutward/></IconButton>}
            {!mobile && !show && <Box sx={{position: "absolute", zIndex: "3", top: 0, borderRadius: rad, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(0,0,0,0.3)", p: 2, display: "flex", alignItems: "end"}}>
              <Typography variant='body' sx={{color: "white", pb: 5}}>{text}</Typography>
            </Box>}
          </Button>
        </Tooltip>
      </Box>
    </Box>
  )
}

export default function Work({cinRef, phoRef}) {
  const vidText = "Great cinematography is more than pressing record. It's about portraying the mood and emotions in a way that leaves your audience with a hunger to experience it for themselves."
  const phoText = "Great photography is about more than just taking pictures. It's about capturing the emotions and memories of your special moments in a way that is both artistic and timeless. Let me help you tell your story through beautiful, one-of-a-kind images."
  return (
    <Box sx={{ width: '100%' , display: "flex", flexDirection: "column", alignItems: "center", zIndex: 20}}>
      <div ref={cinRef}/>
      <Typography variant="h2" sx={{mb: 5}}>Cinematography</Typography>
      {/* <iframe title="TNQ" src="https://drive.google.com/file/d/1hOLnwcoTIFNZ7zyw1QaOAmM028L4OfM6/preview" width="100%" height="800" allow="fullscreen" frameBorder="0" ></iframe> */}
      {/* <iframe width="100%" height="800" src="https://www.youtube-nocookie.com/embed/ntysR4a1M24?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowFullScreen></iframe> */}
      <WorkBox photo="/images/SydSun.JPG" text={vidText} portrait={false} link="/cinematography"/>
      <Box sx={{height: 50}}/>
      <div ref={phoRef}/>
      <Typography variant="h2"sx={{mb: 5}}>Photography</Typography>
      <WorkBox photo="/images/mount_amos.JPG" text={phoText} portrait={false} link="https://www.instagram.com/alex_fiebig/"/>
      {/* <ImageBox/> */}
    </Box>
  );
}
