import { CheckCircle } from "@mui/icons-material";
import { Box, TextField, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";

const initialFormValues = {
  fullName: "",
  company: "",
  email: "",
  message:"",
  formSubmitted: false,
  success: false
}

export const useFormControls = () => {
  // We'll update "values" as the form updates
  const [values, setValues] = useState(initialFormValues);
  // "errors" is used to check the form for errors
  const [errors, setErrors] = useState({});
  const validate = (fieldValues = values) => {
    // this function will check if the form values are valid
  }
  const handleInputValue = (fieldValues = values) => {
    // this function will be triggered by the text field's onBlur and onChange events
  }
  const handleFormSubmit = (ref) => {
    const formData = new FormData(ref.current);
    const values = {};
    for (const [key, value] of formData.entries()) {
      values[key] = value;
    }
    console.log('values', values)
    // this function will be triggered by the submit event
    mailto(values);
  }

  const formIsValid = () => {
    return true
    // this function will check if the form values and return a boolean value
  }
 return {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors
  };
}

function SubmittedMessage() {
  return (
    <div style={{position: "absolute", transition: "opacity 500ms ease-in", top: 0, left: 0, width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
      <CheckCircle fontSize="large" color="success" sx={{mr: 2}}/>
      <Typography variant="h3" style={{color:"white"}}>Thank you for your message!</Typography>
    </div>
  )
}

function mailto(values) {
  const string = `mailto:alexander.fiebig00@gmail.com?subject=Re: Expression of interest&body=Hi Alex,\n\n${values.message}\n\nKind regards,\n${values.fullName}\n\nCompany: ${values.company}\nEmail: ${values.email}`;
  // replace all
  
  window.location = string.replace(/\n/g, '%0D%0A');
}

function EmailForm() {
  const [submitted, setSubmitted] = useState(false);
  const {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors
  } = useFormControls();

  const spacing = 20;
  const ref = React.useRef(null);
  return (
  <div style={{margin: 50, textAlign: "left"}}>
    {submitted?
    <SubmittedMessage/>:
    <div>
      <Typography variant="h3" style={{color:"white"}}>Get in touch</Typography>
      <div style={{height: 50}}/>
      <form ref={ref}>
        <TextField name="fullName" onBlur={handleInputValue} onChange={handleInputValue} label="Full Name" fullWidth autoComplete="none" {...(errors["fullName"] && { error: true, helperText: errors["fullName"] })}/>
        <div style={{height: spacing}}/>
        <TextField name="company" onBlur={handleInputValue} onChange={handleInputValue} label="Company" fullWidth autoComplete="none" {...(errors["company"] && { error: true, helperText: errors["company"] })}/>
        <div style={{height: spacing}}/>
        <TextField name="email" onBlur={handleInputValue} onChange={handleInputValue} label="Email" fullWidth autoComplete="none" {...(errors["email"] && { error: true, helperText: errors["email"]  })}/>
        <div style={{height: spacing}}/>
        <TextField name="message" onBlur={handleInputValue} onChange={handleInputValue} label="Message" fullWidth multiline rows={5} autoComplete="none" {...(errors["message"] && { error: true, helperText: errors["message"] })}/>
        <div style={{height: spacing}}/>
        <Button color="secondary" variant="contained" disabled={!formIsValid()} onClick={()=>{handleFormSubmit(ref);
          // setSubmitted(true);
          }
        }>Submit</Button>
      </form>
    </div>
    }
  </div> 
  )
}

export default function Contact() {
  // Change image size if aspect ratio of div greater than image
  const [imageSize, setImageSize] = useState({width: "100%", height: "auto"});
  const [windowSize, setWindowSize] = useState({width: window.innerWidth, height: window.innerHeight});
  const imageRef = React.useRef(null);
  useEffect(() => {
    function handleResize() {
      setWindowSize({width: window.innerWidth, height: window.innerHeight});
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (imageRef.current) {
      const image = imageRef.current;
      const imageWidth = image.naturalWidth;
      const imageHeight = image.naturalHeight;
      const imageRatio = imageWidth / imageHeight;
      const divWidth = image.parentElement.offsetWidth;
      const divHeight = image.parentElement.offsetHeight;
      const ratio = divWidth / divHeight;
      if (imageHeight === 0) {
        if (ratio > 3992/2992)
          setImageSize({width: "100%", height: "auto"});
        else
          setImageSize({width: "auto", height: "100%"});
        return
      }
      if (ratio > imageRatio) {
        setImageSize({width: "100%", height: "auto"});
      } else {
        setImageSize({width: "auto", height: "100%"});
      }
    }
  }, [imageRef, windowSize]);

  return (
    <div>
      <Typography variant="h2" color="white" sx={{mb: 5}}>Contact</Typography>
        <Box sx={{marginTop: 2, overflow: "hidden", minHeight: "50vh", position: "relative", borderRadius: 3}}>
          <img ref={imageRef} src="/images/Bronte3.JPG" alt="Bronte" style={{position: "absolute", bottom: 0, right: 0, opacity: 0.4, zIndex: -1, ...(imageSize)}} />
          <EmailForm />
        </Box>
    </div>
  );
}