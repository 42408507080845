import React, { useEffect } from "react";
import Page from './Page';
import { Typography, Box, IconButton } from '@mui/material';
import { PlayArrow } from "@mui/icons-material";

const videos = [
  {
    url: "https://www.youtube-nocookie.com/embed/7bdfHPwwq-U?rel=0",
    // url: "https://www.youtube-nocookie.com/embed/ntysR4a1M24?rel=0&autoplay=1&mute=1",
    coverPhoto:"/images/Mackay_Cay.jpg",
    text: "Exploring beautiful Tropical North Queensland in the spring of 2022.",
    title: "Tropical North Queensland 2022"
  },
  {
    url: "https://www.youtube-nocookie.com/embed/ntysR4a1M24?rel=0",
    coverPhoto:"/images/SydSun.JPG",
    text: "A short film about the last month of freedom before the pandemic hit.",
    title: "Last Month of Freeedom"
  }
]

function VideoBox({data, before=false}) {
  const minWidth = "450px"
  const [clicked, setClicked] = React.useState(false)
  return (
    <Box sx={{display: "flex", flexWrap: "wrap", flexDirection: "row", alignItems: "center", justifyContent: "space-between", ...(before && {flexDirection: "row-reverse"})}}>
      <Box sx={{height: "100%", width: "200px", flexGrow: 1, flexShrink: 0, display: "flex", flexDirection: "column", alignItems: "center", ...(before?{pr:0, pl: 1}:{pl:0, pr: 1})}}>
        <Typography variant="h2" sx={{mb:2}}>{data.title}</Typography>
        <Typography variant="body" fontSize="16pt" sx={{mb:5}}>{data.text}</Typography>
      </Box>
      <Box sx={{flexGrow: 1, minWidth: minWidth, minHeight: "400px", height: "100%", aspectRatio: 1.5, position: "relative"}}>
        <iframe width="100%" style={{minHeight: "400px"}} src={data.url} title={data.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowFullScreen></iframe>
        {!clicked && 
          <><img src={data.coverPhoto} alt={data.title} style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%", objectFit: "cover"}}/>
            <IconButton onClick={() => setClicked(true)} style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}><PlayArrow sx={{transform: "scale(2)"}}/></IconButton>
          </>
        }
      </Box>
    </Box>
  )
}

export default function Cinematography({containerSize}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  })
  return (
    <Page pad={true} containerSize={containerSize}>
      <Box sx={{mt: 5, mb: 1}}>
        <Typography variant="h2" sx={{mt: 5, mb: 5}}>Cinematography</Typography>
        {
          videos.map(
            (video, index) =>  {
              return <Box key={index} sx={{...(index<videos.length-1&&{mb:15})}}> 
                <VideoBox data={video} before={index%2===0}/>
              </Box>
            }
          )
        }
      </Box>
    </Page>
  );
}