import React from 'react';
import { ScreenSizeProvider } from '../context/ScreenContext';
import BackgroundImage from '../components/BackgroundImage';
import Header from '../components/Header';
import { Container } from '@mui/material';
import Footer from '../components/Footer';

export default function Page({children, containerSize, image=false, pad=false}) {
  return (
    <ScreenSizeProvider>
      <div className="App" style={{position: "relative"}}>
        {image && <BackgroundImage src="/images/Dive.jpg" alt="Alex Fiebig" height="130vh" maxHeight="1500px" minHeight="700px"/>}
        <Header size={containerSize} pad={pad}/>
        <Container maxWidth={containerSize}>
          {children}
          <Footer/>
        </Container>
      </div>
    </ScreenSizeProvider>
  )
}